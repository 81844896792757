
import React from 'react';


const ConferenceSpeicalGuestList = () => {
    const listContainerStyle = {
      display: 'flex',
      flexDirection: 'column',
    };

    const names = ['Mizinga Melu',
      'Hon. Felix Mutati',
      'Choolwe Nalubamba ',
      'Clement Sinyangwe',
      'Gilbert Lungu ',
      'Roy Muyelu ',
      'James Chona ',
      'Komba Malukutila ',
      'Chavunga Lungu ',
      'Tisa Mulenga',
      'Mwamba Mutale ',
      'Masiliso Mutumba ',
      'Emmanuel Mwanza ',
      'Dumisani Lingamangali Ncube',
      'Dr. Caleb Fundanga',
      'Sandi Chimpala',
      'Kali Lupenga ',
      'Zeko Mbumwae',
      'Andrew Shaw ',
      'Malikano Mwanza',
      'Lindiwe Banda',
      'Harton Maliki ',
      'Sarah Lloyd ',
      'Thomas Banza ',
      'Bruce Jaani ',
      'Venus Hampinda ',
      'Brian Kalero ',
      'Kennedy Chishimba ',
      'Rose Kavimba ',
      'Chimango Chikwanda ',
      'James Kalambata',
      'Kapwila Shachile ',
      'Mato Shimabale ',
      'Boston Nkuname',
      'Stanley Tamele ']

    const companies =
      ['ABSA',
        'Ministry of Science & Technology ',
        'ZICTA',
        'ICTAZ ',
        'Cellulant ',
        'Probase',
        'Zamtel',
        'Fintech at MTN Zambia',
        'Zambia Tourism Agency',
        'Proflight',
        'Airtel',
        'Just Tap',
        'E-Mark',
        'DLN Entrepreneurship Institute ',
        'Former Governor - BOZ',
        'Techtrends Zambia',
        'Savanacom',
        'Infratel',
        'Paratus',
        'Blockhouse Media Zambia',
        'Women in Technology',
        'CMC',
        'CMC',
        'CMC',
        'CMC',
        'CMC',
        'CMC',
        'CMC',
        'CMC',
        'CMC',
        'CMC',
        '',
        'CMC',
        'CMC',
        'CMC',]

    // The designations will have empty rows in the list
    const designations = ['Managing Director',
      '  Minister of Science & Technology',
      '  Director General',
      '  President of ICTAZ',
      '  Country Manager',
      '  Chief Executive Officer',
      '  Chief Commercial Officer',
      '  Managing Director',
      '  Director Marketing',
      '  Pilot',
      '  Head-Fibre Internet',
      '  Marketing Manager',
      '  Chief Executive Officer',
      '  Chief Executive Officer ',
      '  Special Assisant to the President of Zambia',
      '  Executive Director of the Macro Economic and Financial Management Institute (MEFMI)',
      '  Tech Blogger and ICT Writer',
      '  Chief Technology Officer',
      '  Chief Information Officer',
      '',
      '  Airtel Business Director',
      '  ',
      '  ',
      '  ',
      '  ','  ',
      '  ',
      '  ',
      ' ', 
      ' ',
      '  ',
      '  ',
      'Cheif of Staff',
      '  ',
      '  ',
      '  '];
      console.log("length of names: ", names.length)
      console.log("length of companies: ", companies.length)
      console.log("length of designations: ", designations.length)
      return (
        <div style={{ backgroundColor: "#f7f7f7", padding: "20px", margin: 3 }}>
          <h1>Hackathon Guest List</h1>
          <table style={{ borderCollapse: "collapse", width: "100%", marginRight: 20}}>
            <thead>
              <tr style={{ backgroundColor: "#fff", borderBottom: "1px solid #ddd" }}>
                <th style={{ padding: "4px", marginRight: 30, textAlign: "left" }}>Name</th>
                <th style={{ padding: "4px", marginRight: 30, textAlign: "left" }}>Company</th>
                <th style={{ padding: "4px", marginRight: 30, textAlign: "left" }}>Position</th>
              </tr>
            </thead>
            <tbody>
              {names.map((name, index) => {
                const backgroundColor = index % 2 === 0 ? "#fff" : "#f7f7f7";
                return (
                  <tr key={name} style={{ backgroundColor, borderRadius: "5px" }}>
                    <td style={{ padding: "4px", marginRight: 30, fontSize: 14 }}>{name}</td>
                    <td style={{ padding: "4px", marginRight: 30,fontSize: 14 }}>{companies[index]}</td>
                    <td style={{ padding: "4px", marginRight: 30,fontSize: 14 }}>{designations[index]}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <button
                   style={{ backgroundColor: "lightgray", border: "none", color: "black", padding: 10, width: "100%", fontFamily: "Poppins", fontWeight: 600,  marginTop: 20 }}
                    // on click, redirect to 
                    onClick={() => window.location.href = "/absa/hackathon"}
                  >
                    {" "}
                    Back
                  </button>
        </div>
      );
      
  };

  export default ConferenceSpeicalGuestList;
