import React from 'react';
import ProgramOverview from '../assets/ProgramOverview.png'
export default function ConferenceProgrammeImage() {
    return (
        <div>
            <img src={ProgramOverview} alt="Program activities" style={{width: "100%"}}/>
            <button
                    style={{ backgroundColor: "lightgray", border: "none", color: "black", padding: 10, width: "100%", fontFamily: "Poppins", fontWeight: 600,  marginTop: 20,}}
                    // on click, redirect to 
                    onClick={() => window.location.href = "/absa/hackathon"}
                  >
                    {" "}
                    Back
                  </button>
        </div>
    )
}