// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
  apiKey: "AIzaSyCruLXSh5Gx0QSMcMGWW2kyhEPhi_X0Slw",
  authDomain: "tafika-profile.firebaseapp.com",
  databaseURL: "https://tafika-profile-default-rtdb.firebaseio.com",
  projectId: "tafika-profile",
  storageBucket: "tafika-profile.appspot.com",
  messagingSenderId: "1095957484169",
  appId: "1:1095957484169:web:7fe2c3362bec8c0c30ac58"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
// Initialize Analytics and get a reference to the service
export const analytics = getAnalytics(app);
