import ProgramEventsImg from '../assets/ProgramEvents.jpeg'
import Cruise from '../assets/Cruise.jpeg'
import Train from '../assets/Train.jpeg'

export default function ProgramEvents() {
    return (
        <div>
            <img style={{width: '100vw'}}
            src={ProgramEventsImg} alt="Program Events" />
            <img style={{width: '100vw'}}
            src={Cruise} alt="Cruise" />
            <img style={{width: '100vw'}}
             src = {Train} alt="Train" />
        </div>
    )
}
