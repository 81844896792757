import React from 'react';
import { useParams } from 'react-router-dom';
export default function IncorrectLinkWarning() {
    const { link } = useParams();
    const http_link = `http://${link}`;
    return (
        <>
            <div style={{ margin: 10, border: '1px solid blue', borderRadius: 10, padding: 10 }}>
                <h1>JustTap link warning</h1>
                <p> The link you have clicked is trying to redirect you to http://{link}</p>
                <p><a href={http_link}> Click here to proceed </a></p>

            </div>
            <br />
            <br />
            <br />
            <div style={{ padding: 10, margin: 10 }}>
                <i>If this link was meant to redirect you to your social media link, make sure to put
                    the full link, including the http:// prefix
                </i>
            </div>
        </>
    )
}