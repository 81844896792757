import React from 'react';

import ABSA_X_JustTap from "../assets/ABSA_X_JustTap.jpeg";
import BlackPVCCardIcon from "../assets/BlackCardIcon.png"
import BlackMetalCardIcon from "../assets/BlackMetalCardIcon.png";
// import BlackPVCCardIcon from "../assets/BlackPVCCardIcon.svg";
import BlueCardIcon from "../assets/BlueCardIcon.png";
import GreenCardIcon from "../assets/GreenCardIcon.png";
import GoldCardIcon from "../assets/GoldCardIcon.png";
import Sponsor1Zamtel from "../assets/sponsors/Sponsor1Zamtel.jpg";
import Sponsor2Emark from "../assets/sponsors/Sponsor2Emark.png";
import WhatsAppImage1 from "../assets/sponsors/WhatsApp Image 2023-05-08 at 6.57.22 PM (1).jpeg";
import WhatsAppImage2 from "../assets/sponsors/WhatsApp Image 2023-05-08 at 6.57.22 PM (2).jpeg";
import WhatsAppImage3 from "../assets/sponsors/WhatsApp Image 2023-05-08 at 6.57.22 PM (3).jpeg";
import WhatsAppImage4 from "../assets/sponsors/WhatsApp Image 2023-05-08 at 6.57.22 PM (4).jpeg";
import WhatsAppImage from "../assets/sponsors/WhatsApp Image 2023-05-08 at 6.57.22 PM.jpeg";
import WomenInTechWordless from "../assets/sponsors/WomenInTechWordless.jpeg";
export default function JustTapSponsor() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
       <h1>Sponsors</h1>
       {/* show all images */}
       <div>
       <img src={ABSA_X_JustTap} alt="Just Tap logo" style={{maxWidth: "100%", margin: 10}} />
       {/* Making two columsn, where each item in the column is centered. Each column is 50/50 */}
       <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
        <div style={{width: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <img src={Sponsor1Zamtel} alt="Zamtel logo" style={{width: "90%", margin: 30}} />
            <img src={Sponsor2Emark} alt="Emark logo" style={{width: "90%", margin: 30}} />
            <img src={WhatsAppImage1} alt="WhatsAppImage1" style={{width: "90%", margin: 30, marginTop: 65}} />
            </div>
            <div style={{width: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <img src={WomenInTechWordless} alt="WomenInTechWordless" style={{width: "90%", margin: 30}} />
            <img src={WhatsAppImage2} alt="WhatsAppImage2" style={{width: "90%",  marginBottom: 50, marginLeft: 30, marginRight: 30}} />
            <img src={WhatsAppImage3} alt="WhatsAppImage3" style={{width: "90%", marginLeft: 30, marginRight: 30, paddingTop: 0, paddingTop: 0}} />
            {/* <img src={Wh  atsAppImage} alt="WhatsAppImage" style={{width: "90%", margin: 30}} /> */}
            


        </div></div></div>
        
        
        {/* add a thick line */}
        <hr style={{width: "100%", margin: 30, color: "black"}} />
        <div style={{margin: 10, fontFamily: 'poppins', fontSize: 20, border: '1px solid black', padding: 20, borderRadius: 10}}> JustTap is a proud sponsor of the ABSA Hackathon. Find out more about how to get your own JustTap NFC buisness contact card here: 
            <span style={{color: "blue"}}><a href="https://shop.justtap.us"> shop.justtap.us</a></span>
        </div>
        <h1 style={{marginTop: 30}}> Our products </h1>
        <div style={{ overflowX: "scroll", whiteSpace: "nowrap", width: "100%", marginBottom: 40 }}>
                    {/* Black metal cardd, blue pvc card, gold metal card, black pvc card, green pvc card */}
                    <img src={BlackMetalCardIcon} style={{ width: 140, margin: 10 }} alt="Black Metal Card" />
                    <img src={BlueCardIcon} style={{ width: 140, margin: 10 }} alt="Blue PVC Card" />
                    <img src={GoldCardIcon} style={{ width: 140, margin: 10 }} alt="Gold Metal Card" />
                    <img src={BlackPVCCardIcon} style={{ width: 140, margin: 10 }} alt="Black PVC Card" />
                    <img src={GreenCardIcon} style={{ width: 140, margin: 10 }} alt="Green PVC Card" />

                  </div>
                  <div style={{
                    // display: 'flex', 
                    // flexDirection: 'row'
                  }}>
                    <button
                     style={{ backgroundColor: "white", borderRadius: 10, boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25), inset 0px 1px 2px rgba(0, 0, 0, 0.25)", border: "none", color: "black", padding: 10, width: "100%", fontFamily: "Poppins", marginBottom: 70, fontWeight: 600, }}
                      // on click, redirect to shop.justtap.us
                      onClick={() => window.location.href = "https://shop.justtap.us/"}
                    >
                      {" "}

                      Shop Now
                    </button>
                   

                    {/* Center all things within the div */}

                    
                  </div>
                  {/* anchor the button to the bottom */}
                  <button
                    style={{ backgroundColor: "lightgray", border: "none", color: "black", padding: 10, width: "100%", fontFamily: "Poppins", fontWeight: 600,
                    }}
                    // on click, redirect to 
                    onClick={() => window.location.href = "/absa/hackathon"}
                  >
                    {" "}
                    Back
                  </button>

    </div>
  );
}