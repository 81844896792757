import React, { useState, useEffect } from "react";
import logo from "../assets/logo.jpeg";
// css file
import "./Home.css";
// icons
import { BiShareAlt } from "react-icons/bi";
import { AiOutlineQrcode } from "react-icons/ai";
import { RiContactsFill } from "react-icons/ri";
import {  logEvent } from "firebase/analytics";

// web share
import { RWebShare } from "react-web-share";
// React Router Dom
import { useParams } from "react-router-dom";
// vCards
import QrCode from "../components/QRCode";
//save contact
import SaveContact from "../components/SaveContact";
// firebase db
import { db, analytics } from "../firebase";
// firebase functions
import {doc, getDoc } from "firebase/firestore";
// import loader
import Loader from "../components/Loader";
// dumy profile
import dumyProfile from "../assets/download.png";
import UserSchema from '../UserInterface';
import SocialLinkButton from "../components/SocialLinkButton.tsx";
import DeletedConferenceContactCard from "../components/DeletedConferenceContactCard.js";
import browserIcon from "../assets/browser.png";
import { HiOutlineShoppingCart } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

interface SocialIconInfoPair {
  link: string;
  icon: string,
  size?: number,
  name: string;
}

const Home = () => {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState<UserSchema | null>(null);
  const [socialIcons, setSocialIcons] = useState<SocialIconInfoPair[]>([]);
  const [wrstBand, setWrstBand] = useState(false);

  let navigate = useNavigate();

 
  let { id } = useParams();
  // TODO: do not allow IDs to increment, as they currently are

  //use effect
  useEffect(() => {
    function populate_social_icons(user: UserSchema) {
      // Go through the user object and populate the socialIcons array
      // with the social media links and icons
      // Office, Mobile, Whatsapp, Email, Instagram, LinkedIn, Facebook, TikTok, Twitter, Youtube
      let social_icons: SocialIconInfoPair[] = [];
      if (user.mobileNumber) {
        social_icons.push({
          link: `tel:${user.mobileNumber}`,
          icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/Phone_rounded.svg/1200px-Phone_rounded.svg.png",
          name: "Mobile",
          size: 2,
          
        });
      }
      if (user.officeNumber) {
        social_icons.push({
          link: `tel:${user.officeNumber}`,
          icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b8/Telephone_icon_blue_gradient.svg/1024px-Telephone_icon_blue_gradient.svg.png",
          name: "Office",
        });
      }
      if (user.email) {
        social_icons.push({
          link: `mailto:${user.email}`,
          icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4e/Mail_%28iOS%29.svg/2048px-Mail_%28iOS%29.svg.png",
          name: "Email",
          size: 3,
        });
      }
      if (user.whatsappNumber) {
        social_icons.push({
          link: `https://wa.me/${user.whatsappNumber}`,
          icon: "https://cdn.cdnlogo.com/logos/w/35/whatsapp-icon.svg",
          name: "Whatsapp",
        });
      } 
      if (user.showWebsiteIcon && user.website) {
        social_icons.push({
          link: user.website,
          icon: browserIcon,
          name: "CUSTOMWebsite",
        });
      } 
      if (user.instagramUrl) {
        social_icons.push({
          link: user.instagramUrl,
          icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/2048px-Instagram_logo_2016.svg.png",
          name: "Instagram",
        });
      }
      if (user.linkedInUrl) {
        social_icons.push({
          link: user.linkedInUrl,
          icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/LinkedIn_logo_initials.png/800px-LinkedIn_logo_initials.png",
          name: "LinkedIn",
        });
      }
      if (user.facebookUrl) {
        social_icons.push({
          link: user.facebookUrl,
          icon: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTEzf9tE4gWBhNVV5HzlFK1blHO565LycTk9EjBWxezQ&s",
          name: "Facebook",

        });
      }
      if (user.venmoUrl) {
        social_icons.push({
          link: user.venmoUrl,
          icon: "https://images.ctfassets.net/gkyt4bl1j2fs/ym6BkLqyGjMBmiCwtM7AW/829bf561ea771c00839b484cb8edeebb/App_Icon.png?w=276&h=276&q=50&fm=png&bg=transparent",
          name: "Venmo",
        })
      }
      if (user.tiktokUrl) {
        social_icons.push({
          link: user.tiktokUrl,
          icon: "https://cdn.worldvectorlogo.com/logos/tiktok-logo-2--1.svg",
          name: "TikTok",
        });
      }
      if (user.twitterUrl) {
        social_icons.push({
          link: user.twitterUrl,
          icon: "https://camo.githubusercontent.com/ac6e1101f110e5f500287cf70dac72519687620deefb5e8de1fa7ba6a3ba2407/68747470733a2f2f6564656e742e6769746875622e696f2f537570657254696e7949636f6e732f696d616765732f706e672f747769747465722e706e67",
          name: "Twitter",
        });
      }
      if (user.youtubeUrl) {
        social_icons.push({
          link: user.youtubeUrl,
          icon: "https://icon-library.com/images/netflix-app-icon-png/netflix-app-icon-png-25.jpg",
          name: "Youtube",
        });
      }

        if (user.customIcon && user.customIconUrl && user.customIconName) {
          social_icons.push({
            link: user.customIconUrl,
            icon: user.customIcon,
            name: "CUSTOM" + user.customIconName,
          });
      }
      if (user.customPdf && user.customPdfName) {
        social_icons.push({
          link: user.customPdf,
          icon: "https://play-lh.googleusercontent.com/Sk7Dty2fwulphkw7uyJ0fAFqWLNK-b4dt7S1lkfoK9-IYRdHYuPPk3yEcWlZR5KIlw",
          name: "CUSTOM" + user.customPdfName,
        });
      }
      setSocialIcons(social_icons);
    }
    let collectionName = "";
    if (!id) {
      console.log("Error: id not defined");
      return;
    }
    if (!db) {
      console.log("Error: db not defined");
      return;
    }
    if (id.substring(0, 5).includes("wrst")) {
      setWrstBand(true);
      return;
    }
    collectionName="users";
    try {
      logEvent(analytics, 'JustTap card viewed');
    } catch (error) {
      console.log(error);
    }
    try {
      const getData = async () => {
        const docRef = doc(db, collectionName, id);
        const docSnap = await getDoc(docRef);
        let data =  docSnap.data();
        if (!data) {
          window.location.href = `https://profile.justtap.us/${id}`
          return;
        }
        const user_object = {
          address: data.address,
          city: data.city,
          state: data.state,
          zip: data.zip,
          country: data.country,
          officeNumber: data.officeNumber,
          userId: data.userId,
          mobileNumber: data.mobileNumber,
          email: data.email,
          linkedInUrl: data.linkedInUrl,
          facebookUrl: data.facebookUrl,
          tiktokUrl: data.tiktokUrl,
          instagramUrl: data.instagramUrl,
          twitterUrl: data.twitterUrl,
          youtubeUrl: data.youtubeUrl,
          website: data.website,
          whatsappNumber: data.whatsappNumber,
          coverPic: data.coverPic,
          profilePic: data.profilePic,
          Title: data.Title,
          firstName: data.firstName,
          secondName: data.secondName,
          companyName: data.companyName,
          venmoUrl: data.venmoUrl,
          customIconUrl: data.customIconUrl,
          customIcon: data.customIcon,
          customIconName: data.customIconName,
          showWebsiteIcon: data.showWebsiteIcon,
          customPdf: data.customPdf,
          customPdfName: data.customPdfName,
          // Add the conference pacakge field, which may not be in the database
          conferencePackage: data.conferencePackage || "none",
        }
        setUser(user_object);
        if (!data) {
          console.log("No such document!");
          return;
        }
        populate_social_icons(user_object);
      };
      getData();
      
    } catch (err) {
      console.log(err.message);
    }
  }, [id]); // TODO: check useEffect dependency list

  if (wrstBand) {
    // return (<ConferenceContactCard user={user} socialIcons={socialIcons} id={id} />);
    return (<DeletedConferenceContactCard />);
  }
  //component render
  return (
    <>
      {user ? (
        <div>
          <div className="row">
            <div className="col col-sm-12 col-md-8 col-lg-5 col-xl-4 mx-auto ">
              {open ? (
                <QrCode onClickHandler={() => setOpen(false)} user={user} />
              ) : (
                <div className="">
                  <div className="d-flex justify-content-end pt-3">
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: 1, fontSize: 10}}>
                      <div style={{paddingLeft: 24}}>
                      <a href="http://shop.justtap.us"><HiOutlineShoppingCart className="me-4 icons" size={24} /></a>
                      </div>
                   
                    </div>
                    <RWebShare
                      data={{
                        text: "You can view my Justtap here:",
                        url: `https://www.justtap.us/${id}`,
                        title: user?.firstName + " Digicard",
                      }}
                      onClick={() => console.log("shared successfully!")}
                    >
                      <BiShareAlt className="me-4 icons" size={25} />
                    </RWebShare>

                    <AiOutlineQrcode
                      className="me-4 icons"
                      size={25}
                      onClick={() => setOpen(true)}
                    />
                  </div>
                  <img
                    src={user?.coverPic ? user?.coverPic : logo}
                    className="img-fluid mb-3 mb-sm-0 main_logo mt-2"
                    alt="..."
                    style={{
                      objectFit: "cover",
                      maxHeight: "270px",
                      minHeight: "270px",
                      width: "100%",
                    }}
                  />

                  <div className="main_card">
                    <img
                      src={user?.profilePic ? user?.profilePic : dumyProfile}
                      className="profile"
                      alt="profile"
                    />
                    <h3 className="mt-4">
                      {user?.firstName + " " + user?.secondName}
                    </h3>
                    <div>
                      <p className="mt-2 mb-0 fw-normal">{user?.Title}</p>
                      <p className="mt-2">{user?.companyName}</p>
                    </div>
                    <div>
                      <button
                        className="save_btn mt-4"
                        onClick={() => SaveContact(user)}
                      >
                        {" "}
                        <RiContactsFill
                          className="me-3"
                          style={{ marginTop: "-6px" }}
                        />
                        Save Contact
                      </button>
                    </div>

                    <div className="d-flex justify-content-around flex-wrap mt-5">
                      {socialIcons?.map((item, index) => {
                        if (item.name.startsWith("CUSTOM")) {
                          return (
                            <SocialLinkButton key={index}
                              link={item.link}
                              icon={item.icon}
                              // all characters after 'CUSTOM' in name
                              name={item.name.substring(6)}
                              custom={true}
                              size={item.size} />);
                        }
                        return (
                          <SocialLinkButton key={index}
                            link={item.link}
                            icon={item.icon}
                            name={item.name}
                            size={item.size} />);
                      })
                      }
                    </div>
                    <hr />
                    <p style={{ marginTop: 20, marginBottom: 4, fontStyle: 'initial' }}><i>The future of networking</i></p>
                    <button
                   
                    style={{  backgroundColor: "black", borderRadius: 10, border: "none", color: "white", padding: 3, fontFamily: "Poppins", marginBottom: 10, fontWeight: 500, fontSize: 12, paddingLeft: 6, paddingRight: 6 }}
                    // on click, redirect to shop.justtap.us
                    onClick={() => window.location.href = "https://shop.justtap.us/"}
                  >
                    {" "}

                    Get yours here
                  </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="loader">
          <Loader />
        </div>
      )}
    </>
  );
};

export default Home;

