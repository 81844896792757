import React from "react"

// Defines the button for the social links.
// There is lots of dead css here, i have not looked into this.
export default function SocialLinkButton({ link, icon, name, size, custom = false}: { link: string, icon: string, name: string, size?: number, custom?: boolean}) {
    const size_offset = 69
    if (!size) {
        size = size_offset
    }
    else {
        size += size_offset
    }
    // /incorrect-link-warning/:link
    // if the link does not contain one of the following, it is not a valid link.
    // instagram linkedin twitter facebook whatsapp tiktok youtube
    let http_link = link;
    if (!custom && !name.includes("Email") && !name.includes("Office") &&!name.includes("Mobile") && !link.includes("instagram") && !link.includes("linkedin") && !link.includes("twitter") && !link.includes("facebook") && !link.includes("whatsapp") && !link.includes("tiktok") && !link.includes("youtube") &&!link.includes('t.co') && !link.includes('youtu.be') && !link.includes("wa.me") && !link.includes("venmo")) { 
        http_link = "/incorrect-link-warning/" + link
    } 

    return (
        <a href={http_link}
           target="_blank"
           className="mb-1"
           rel="noreferrer"> 
            <div className="">
                {/* <div className="social_icons"> {icon} </div> */}
                <img src={icon} alt={name} style={{height: size, width: size, marginTop: 3, marginRight: 23, marginLeft: 23, marginBottom: 5, minWidth: size_offset + 5}} />
                <p className="mt-1 text-black">{name}</p>
            </div>
            </a>
    )

}
