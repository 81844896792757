import React, { useState, useEffect } from "react";
import QRCode from "react-qr-code";
import VCard from "vcard-creator";

// TODO: QrCode does not include image
const QrCode =  ({ onClickHandler, user }) => {
  const [image, setImage] = useState(null);

  var myVCard = new VCard();
  
  myVCard
    // Add personal data
    .addName(user?.secondName, user?.firstName)
    // Add work data
    .addCompany(user?.companyName)
    .addJobtitle(user?.Title)
    .addEmail(user?.email, "WORK")
    .addPhoneNumber(user?.mobileNumber, "CELL")
    .addPhoneNumber(user?.officeNumber, "WORK");
    // if (user?.whatsappNumber) {
    //   myVCard.addPhoneNumber(user?.whatsappNumber, "WhatsApp");
    // }
    if (user?.linkedInUrl) {
      myVCard.addSocial(user?.linkedInUrl, "LinkedIn")
    }
    if (user?.instagramUrl) {
      myVCard.addSocial(user?.instagramUrl, "Instagram")
    } 
    if (user?.venmoUrl) {
      myVCard.addSocial(user?.venmoUrl, "Venmo")
    }
    if (user?.website) {
      myVCard.addURL(user?.website);
    }
    myVCard.addAddress(
      "",
      user?.address,
      user?.city,
      user.state,
      user?.zip,
      user?.country
    );
  return (
    <div
      style={{ height: "100vh", backgroundColor: "white", padding: "30px" }}
    >
      <div className="d-flex flex-column justify-content-center">
        <div className="align-self-end">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={onClickHandler}
          ></button>
        </div>
        <QRCode
          style={{
            background: "white",
            padding: "20px",
            height: "auto",
            maxWidth: "100%",
            width: "100%",
            borderRadius: "15px",
          }}
          // value={`${user?.firstName}${user?.secondName}\n${user?.Title}\n${user?.companyName}\n${user?.email}\n${user?.officeNumber}\n${user?.mobileNumber}\n${user?.whatsappNumber}\n${user.instagramUrl}\n${user?.linkedInUrl}`}
          value={myVCard.toString()}
          className="align-self-center mt-5"
        />
        <div className="text-center mt-5">
          <h5>Scan the QR Code</h5>
          <p>to view my Business Card on another device</p>
        </div>
      </div>
    </div>
  );
};

export default QrCode;
