import React from 'react';
export default function ConferenceProgrammeImage() {
  const times = [
    '08:00 - 08:45',
    '09:00 - 09:10',
    '09:10 - 09:20',
    '09:30 - 09:50',
    '09:50 - 10:00',
    '10:00 - 10:15',
    '10:15 - 10:45',
    '10:45 - 11:00',
    '11:00'
];

const events = [
    'Arrival Of Guests',
    'Welcoming of Guests and National Anthem',
    'Remarks by the Director of Digital & Technology',
    'Top of Mind Segment',
    'Speech by the Vice-Chancellor Of University Of Lusaka',
    'Demo by 2 contestants',
    'Announcement Of Top Three Winners and Prize Giving',
    'Closing Remarks by Acting MD',
    'Networking and Socialization: Guests leave at their leisure'
];
    return (
        <div>          
          <div style={{margin: 10, display: "flex", flexDirection: "column", alignItems: "center"}}>
            <table stlye={{ border: "1px solid black", width: "100%", fontFamily: "Poppins", fontWeight: 600

            }}>
                <tr>
                    <th style={{ border: "1px solid black", padding: 14}}>
                    Time
                    </th>
                    <th style={{ border: "1px solid black", padding: 14}}>
                    Activity
                    </th>
                </tr>
                {times.map((time, index) => (

                    <tr style={{ border: "1px solid black"

                    }}>
                        <td style={{ border: "1px solid black", padding: 14}}>
                            {time}
                        </td>
                        <td style={{ border: "1px solid black", padding: 14}}>
                            {events[index]}
                        </td>
                    </tr>
                ))}
            </table>
            <button
                   style={{ alignSelf: 'center', backgroundColor: "lightgray", border: "none", color: "black", padding: 10, width: "90%", fontFamily: "Poppins", fontWeight: 600,  margin: 20 }}
                    // on click, redirect to 
                    onClick={() => window.location.href = "/absa/hackathon"}
                  >
                    {" "}
                    Back
                  </button>
        </div></div>
    )
}

// 
		 	 	 		
			
				
			