import React from 'react';
// import logo from "src/assets/logo.jpeg";
// css file
import "../pages/Home.css";
// icons
import { BiShareAlt } from "react-icons/bi";
import { AiOutlineQrcode } from "react-icons/ai";
import { RiContactsFill, RiRoundedCorner } from "react-icons/ri";
import NfcIcon from '../assets/nfc-icon.png';
import BlackPVCCardIcon from "../assets/BlackCardIcon.png"
import BlackMetalCardIcon from "../assets/BlackMetalCardIcon.png";
import ABSA_X_JustTap from "../assets/ABSA_X_JustTap.jpeg";
import BlueCardIcon from "../assets/BlueCardIcon.png";
import GreenCardIcon from "../assets/GreenCardIcon.png";
import TiktokIcon from "../assets/TiktokIcon.svg";
import WhatsappIcon from "../assets/WhatsappIcon.svg";
import FacebookIcon from "../assets/FacebookIcon.svg";
import EmailIcon from "../assets/EmailIcon.svg";
import SaveContactButton from "../assets/SaveContactButton.svg";
import LocationIcon from "../assets/LocationIcon.svg";
import OverviewIcon from "../assets/OverviewIcon.svg";
import ProgramIcon from "../assets/ProgramIcon.svg";
import SponsorIcon from "../assets/SponsorIcon.svg";
import GuestsIcon from "../assets/GuestsIcon.svg";
import DefaultProfileIcon from "../assets/DefaultProfileIcon.png";
import OfficeIcon from "../assets/OfficeIcon.svg";
import InstagramIcon from "../assets/InstagramIcon.svg";
import MobileIcon from "../assets/MobileIcon.svg";
import GoldCardIcon from "../assets/GoldCardIcon.png";
import Logo from "../assets/logo.jpeg";
import BlackJustTapLogo from "../assets/BlackJustTapLogo.png";
import JustTapCross from "../assets/CrossLogo.png"
import JustTapCrossLogo from "../assets/JustTapCrossLogo.png";
import LinkedInIcon from "../assets/LinkedIn.svg";
// web share
import { RWebShare } from "react-web-share";

// vCards
import QrCode from "./QRCode";
//save contact
import SaveContact from "./SaveContact";
// import loader
import Loader from "./Loader";
// dumy profile
// import UserSchema from '../UserInterface';
// import SocialLinkButton from "./SocialLinkButton.tsx";
import ConferenceSocialLink from "./ConferenceSocialLink";


// interface SocialIconInfoPair {
//     link: string;
//     icon: string,
//     size?: number,
//     name: string;
//   }

// export default function ConferenceContactCard({ user, socialIcons, id }: { user: UserSchema | null, socialIcons: SocialIconInfoPair[], id: string}) {

export default function ConferenceContactCard({ user, socialIcons, id }) {
  const [open, setOpen] = React.useState(false);
  // const PackageImage = user?.conferencePackage === "Basic" ? ConferenceOnly : user?.conferencePackage === "Premium" ? FullPackage2 : CeremonyOnly;
  if (!user) {
    return <Loader />;
  }

  // Social Icons need to be updated, and only keep the needed ones
  // Keep Mobile, Office, Email, Instagram, WhatsApp, Facebook, TikTok
  const socialIconsReduced = socialIcons.filter((socialIcon) => {
    if (socialIcon.name === "Facebook" || socialIcon.name === "Whatsapp" || socialIcon.name === "Tiktok" || socialIcon.name === "Email" || socialIcon.name === "Instagram" || socialIcon.name === "Office" || socialIcon.name === "LinkedIn" || socialIcon.name === "Mobile") {
      return true;
    }
    return false;
  });
  const socialIconsWithNewPictures = socialIconsReduced.map((socialIcon) => {
    if (socialIcon.name === "Facebook") {
      socialIcon.icon = FacebookIcon;
    } else if (socialIcon.name === "Whatsapp") {
      socialIcon.icon = WhatsappIcon;
    } else if (socialIcon.name === "Tiktok") {
      socialIcon.icon = TiktokIcon;
    } else if (socialIcon.name === "Email") {
      socialIcon.icon = EmailIcon;
    } else if (socialIcon.name === "Instagram") {
      socialIcon.icon = InstagramIcon;
    } else if (socialIcon.name === "Office") {
      socialIcon.icon = OfficeIcon;
    }

    // Missing: LinkedIn
    // TODO: LinkedIn
    else if (socialIcon.name === "LinkedIn") {
      socialIcon.icon = LinkedInIcon;
    }
    else if (socialIcon.name === "Mobile") {
      socialIcon.icon = MobileIcon;
    }
    return socialIcon;
  });
  const order = ["Mobile", "Office", "Email", "LinkedIn", "Whatsapp", "Facebook", "Instagram"];

  const sortedSocialIcons = socialIconsWithNewPictures.sort((a, b) => {
    return order.indexOf(a.name) - order.indexOf(b.name);
  });






  return (

    // #ffffff background

    <div >
      <div className="row">
        <div className="col col-sm-12 col-md-8 col-lg-5 col-xl-4 mx-auto " style={{ backgroundColor: "#fff5ef" }}>
          


              {/* Goal: Move this line to be position relative */}
              {/* <img className="image_logo_top" src={ABSA_X_JustTap} alt="JustTap x ABSA logo" style={{ width: "100%" }} /> */}
              <img className="image_logo_top" src={JustTapCrossLogo} alt="JustTap x ABSA logo" style={{ width: "100%" }} />
              


                  <div>
                    <div style={{
                      fontFamily: "Poppins", fontStyle: "normal", marginTop: 50,fontWeight: 500, fontSize: 28, textAlign: "center", textDecorationLine: "underline", color: "#000000", marginBottom: 20,
                    }}>Hackathon Information</div>

                    <div style={{ whiteSpace: "nowrap", overflowX: 'scroll', width: "100%", marginBottom: 40, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
                      {/* Add a small margin around each link */}
                      <a href="/conference/conference-program" style={{ marginLeft: 10, marginRight: 10 }}><img src={ProgramIcon} style={{ width: 80 }} alt="Program" /></a>
                      <a href="/conference/conference-program-overview" style={{ marginLeft: 10, marginRight: 10 }}><img src={OverviewIcon} style={{ width: 80 }} alt="Program Overview" /></a>
                      
                      <a href="/conference/conference-sponsors" style={{ marginLeft: 10, marginRight: 10 }}><img src={SponsorIcon} style={{ width: 80 }} alt="Sponsors" /></a>


                   


                      
                    </div>

                 
                    
                  
                  </div>

                 





                  <div style={{
                    fontFamily: "Poppins", fontStyle: "normal", fontWeight: 500, fontSize: 20, textAlign: "center", textDecorationLine: "underline", color: "#000000", marginBottom: 14,
                  }}>
                    Just-tap Products <img src={NfcIcon} alt="nfc logo" style={{width: 30, marginBottom: 4}} /></div>
                  <div style={{ overflowX: "scroll", whiteSpace: "nowrap", width: "100%", marginBottom: 40 }}>
                    {/* Black metal cardd, blue pvc card, gold metal card, black pvc card, green pvc card */}
                    <img src={BlackMetalCardIcon} style={{ width: 140, margin: 10 }} alt="Black Metal Card" />
                    <img src={BlueCardIcon} style={{ width: 140, margin: 10 }} alt="Blue PVC Card" />
                    <img src={GoldCardIcon} style={{ width: 140, margin: 10 }} alt="Gold Metal Card" />
                    <img src={BlackPVCCardIcon} style={{ width: 140, margin: 10 }} alt="Black PVC Card" />
                    <img src={GreenCardIcon} style={{ width: 140, margin: 10 }} alt="Green PVC Card" />

                  </div>
                  {/* align the following things centered */}
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <div style={{
                  alignSelf: "center",
                  }}>
                    <button
                     style={{ backgroundColor: "white", borderRadius: 10, marginBottom: 30, boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25), inset 0px 1px 2px rgba(0, 0, 0, 0.25)", border: "none", color: "black", padding: 10, width: "100%", fontFamily: "Poppins", marginBottom: 10, fontWeight: 600 }}
                      // on click, redirect to shop.justtap.us
                      onClick={() => window.location.href = "https://shop.justtap.us/"}
                    >
                      {" "}

                      Shop Now
                    </button>
                   

                    {/* Center all things within the div */}

                    
                  </div>
                  </div>
                  </div>
                 



              </div>
            </div>
          
   
                  
  );
};

