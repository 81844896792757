import React from 'react';
import logo from '../assets/CrossLogo.png';
export default function DeletedConferenceContactCard() {
    return (
        
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
      }}
    >
        <img src={logo} alt="Just Tap logo" style={{maxWidth: "100%", margin: 0}} />
      <div
        style={{
          borderRadius: '10px',
          border: '2px solid #000000',
          padding: '20px',
          textAlign: 'left',
          margin: 10,
        }}
      >
        
        Thank you for attending! Your conference card has been deactivated. <br /><br />Please contact JustTap to order your card at <span style={{color: 'blue'}}><a href="tel:+260776098835">+260776098835</a></span> or order from our website at <span style={{color: 'blue'}}><a href="http://shop.justtap.us">shop.justtap.us</a></span> 
            </div>
    </div>
  );
}