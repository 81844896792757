import React from "react";
import "./App.css";
import HomeMinimalApps from "./pages/HomeMinimalApps.tsx"
// react router dom
import { Route, Routes, Link } from "react-router-dom";
import PageNotFound from "./components/PageNotFound";
import ConferenceProgramme from './components/ConferenceProgramme.js';
import ConferenceSponsors from './components/ConferenceSponsors.js';
import ConferenceEvents from './components/ConferenceEvents.js';
import ConferenceGuestList from './components/ConferenceGuestList.js';
import IncorrectLinkWarning from './components/IncorrectLinkWarning.js';
import RedirectToAbsa from './pages/RedirectToAbsa.tsx';
import ConferenceProgramOverview from './components/ConferenceProgramOverview.js';
function App() {
  return (
    <>
      <Routes>
        <Route path="/incorrect-link-warning/:link" element={<IncorrectLinkWarning />} />
        <Route path="/conference/conference-program" element={<ConferenceProgramme />} />
        <Route path="/conference/conference-program-overview" element={<ConferenceProgramOverview />} />
        <Route path="/conference/conference-sponsors"element={<ConferenceSponsors />} />
        <Route path="/conference/conference-events"element={<ConferenceEvents />} />
        <Route path="/conference/conference-guests" element={<ConferenceGuestList />} />
        <Route path="/absa/hackathon" element={<RedirectToAbsa />} />
        <Route path="/ABSA/hackathon" element={<RedirectToAbsa />} />
        <Route path="/absa/Hackathon" element={<RedirectToAbsa />} />
        <Route path="/ABSA/Hackathon" element={<RedirectToAbsa />} />
        <Route path="/hackathons/absa/" element={<RedirectToAbsa />} />
        <Route path="/hackathons/ABSA/" element={<RedirectToAbsa />} />
        <Route path="/:id" element={<HomeMinimalApps />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default App;
