import VCard from "vcard-creator";
import FileSaver from "file-saver";
import Resizer from "react-image-file-resizer";


// Resizer.imageFileResizer(
//   file, // Is the file of the image which will resized.
//   maxWidth, // Is the maxWidth of the resized new image.
//   maxHeight, // Is the maxHeight of the resized new image.
//   compressFormat, // Is the compressFormat of the resized new image.
//   quality, // Is the quality of the resized new image.
//   rotation, // Is the degree of clockwise rotation to apply to uploaded image.
//   responseUriFunc, // Is the callBack function of the resized new image URI.
//   outputType, // Is the output type of the resized new image.
//   minWidth, // Is the minWidth of the resized new image.
//   minHeight // Is the minHeight of the resized new image.
// );
const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      600,
      600,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });
export default async function SaveContact(user) {
  let photo_blob = null;
  if (user?.profilePic) {
    try {
      const response = await fetch(user?.profilePic); // Fetch the image URL
      // Compress the image to be max size of 150KB
     


      photo_blob = await response.blob(); // Convert the response to a Blob
      const compressedBlob = await resizeFile(photo_blob);
      // create a blob from the result
      photo_blob = await fetch(compressedBlob).then((r) => r.blob());
      
    } catch (error) {
      console.log(error);
    }
  }

  var myVCard = new VCard();
  myVCard
    // Add personal data
    .addName(user?.secondName, user?.firstName);
  // Add work data
  if (user?.companyName) {
    myVCard.addCompany(user?.companyName);
  }
  if (user?.Title) {
    myVCard.addJobtitle(user?.Title);
  }
  if (user?.email) {
    myVCard.addEmail(user?.email);
  }
  if (user?.mobileNumber) {
    myVCard.addPhoneNumber(user?.mobileNumber, "CELL");
  }
  if (user?.officeNumber) {
    myVCard.addPhoneNumber(user?.officeNumber, "WORK");;
  }
  if (user.linkedInUrl) {
    myVCard.addSocial(user?.linkedInUrl, "LinkedIn");
  }
  if (user?.instagramUrl) {
    myVCard.addSocial(user?.instagramUrl, "Instagram");
  }
  if (user?.facebookUrl) {
    myVCard.addSocial(user?.facebookUrl, "Facebook");
  }
  if (user?.twitterUrl) {
    myVCard.addSocial(user?.twitterUrl, "Twitter");
  }
  if (user?.youtubeUrl) {
    myVCard.addSocial(user?.youtubeUrl, "Youtube");
  }
  if (user?.tiktokUrl) {
    myVCard.addSocial(user?.tiktokUrl, "TikTok");
  }
  if (user?.venmoUrl) {
    myVCard.addSocial(user?.venmoUrl, "Venmo");
  }
  if (user?.customIconName) {
    myVCard.addSocial(user?.customIconUrl, user?.customIconName);
  }
  if (user?.website) {
    myVCard.addURL(user?.website);
  }
  //   .addAddress(null, null, 'street', 'worktown', null, 'workpostcode', 'Belgium')
/**
     * Add address
     *
     * @param  {string} [name='']
     * @param  {string} [extended='']
     * @param  {string} [street='']
     * @param  {string} [city='']
     * @param  {string} [region='']
     * @param  {string} [zip='']
     * @param  {string} [country='']
     * @param  {string} [type='']
     * 'type' may be DOM | INTL | POSTAL | PARCEL | HOME | WORK
     * or any combination of these: e.g. 'WORK;PARCEL;POSTAL'
     * @return {this}
     
addAddress(name?: string, extended?: string, street?: string, city?: string, region?: string, zip?: string, country?: string, type?: string): this;
*/
// user?.address,
// user?.city,
// user.state,
// user?.zip,
// user?.country
console.log("Address information: ", user?.address, user?.city, user?.state, user?.zip, user?.country)
if (user?.address || user?.city || user?.state || user?.zip || user?.country) {
myVCard.addAddress(
    "",
    "",
    user?.address,
    user?.city,
    user?.state,
    user?.zip,
    user?.country,
  );
}
  let new_VCard = null;
  if (photo_blob) {
    const photoDataUrl = await getDataUrlFromBlob(photo_blob);
    // Remove the data type from the image data URL
    // Before is: data:image/png;base64, then the actual data follows.
    const photoDataUrlParts = photoDataUrl.split(",");
    let card = myVCard.toString();
    // find the 'END:VCARD' string
    const endVcardIndex = card.indexOf("END:VCARD");
    // insert the photo data URL before the 'END:VCARD' string
    new_VCard = "" + card.slice(0, endVcardIndex) +
      "PHOTO;ENCODING=BASE64;JPEG:" + photoDataUrlParts[1] + "\r\n";
  }
  let card = new_VCard ? new_VCard : myVCard.toString();
  // Add in the JustTap link to the end of the card
  const endVcardIndex = card.indexOf("END:VCARD");
  const newCard = "" + card.slice(0, endVcardIndex) +
    "URL;TYPE=JustTap - Digital Business Card:http://justtap.us/" + user?.userId + "\r\nEND:VCARD\r\n";
  card = newCard;


  var file = new Blob([card], {
    type: "text/vcard;charset=utf-8",
  });
  if (user.secondName) {
    FileSaver.saveAs(file, `${user.firstName}_${user.secondName}.vcf`);
    return;
  }
  FileSaver.saveAs(file, `${user.firstName}.vcf`);
}

async function getDataUrlFromBlob(blob) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
}
